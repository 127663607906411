import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useState } from 'react';
import YouTube from 'react-youtube';
import { useBodyContext } from 'src/components/Common/ContentWrapper/context';
import { ModalComponent } from 'src/components/Common/Modal';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import { useExperienceEditor } from 'src/lib/Hooks/useExperienceEditor';
import { YouTubePlayer } from 'youtube-player/dist/types';
import { ModalVideoProps, playerVarsType } from './types';

const createPlayerVars = ({
  closedCaptions,
  modestBranding,
  relatedVideos,
  fullScreen,
}: playerVarsType) => {
  return {
    cc_load_policy: (Number(closedCaptions) === 1 ? 1 : undefined) as 1 | undefined,
    modestbranding: Number(modestBranding) as 1 | undefined,
    rel: Number(!relatedVideos) as 1 | 0 | undefined,
    fs: (fullScreen ? 1 : 0) as 0 | 1,
  };
};

const ModalVideo = ({
  closedCaptions,
  headline,
  id,
  intro,
  modestBranding,
  relatedVideos,
  url,
  fullScreen,
}: ModalVideoProps) => {
  const { dispatch, state } = useBodyContext();
  const { activeId, isOpen } = state.modal;
  const { isEEActive } = useExperienceEditor();
  const shouldDisplayModal = activeId === id && isOpen;
  const [player, setPlayer] = useState<null | YouTubePlayer>(null);
  const [videoEnded, setVideoEnded] = useState(false);

  const videoId = url?.split('/').pop();
  const props = {
    controls: { isTransparent: true },
    id,
    isActive: shouldDisplayModal,
    onCloseHandler: () => {
      sendAttribution();
      return dispatch({ type: 'modalClose' });
    },
  };

  const Wrapper = isEEActive ? 'div' : ModalComponent;

  const playerVars = createPlayerVars({
    closedCaptions,
    modestBranding,
    relatedVideos,
    fullScreen,
  });

  const sendAttribution = () => {
    const page = window?.location?.pathname || '';
    if (player) {
      track.video({
        target: player,
        videoType: 'modal',
        id,
        page,
      });
    }
  };

  if (!videoId) {
    // eslint-disable-next-line no-console
    console.log('Missing Modal Video Data!');
    return null;
  }

  return (
    <Wrapper
      {...props}
      className={isEEActive ? 'bg-black border-black border-8 md:container-md' : ''}
    >
      <div className="mt-16 md:mt-0">
        <Text
          aria-labelledby={headline?.value}
          className="text-2xl mb-24 text-center text-white"
          field={headline}
          tag="h2"
        />
        <div className="relative aspect-16/9 bg-black">
          {props.isActive && (
            <>
              {videoEnded && (
                <>
                  <button
                    className="absolute bg-black w-full h-full z-overlay"
                    onClick={() => {
                      player?.playVideo();
                      setVideoEnded(false);
                    }}
                    tabIndex={0}
                  >
                    <div className="relative flex items-center justify-center bg-black bg-opacity-40 rounded-md">
                      <SvgLoader name="Play" color="text-white" className="absolute" size={64} />
                    </div>
                  </button>
                </>
              )}
              <YouTube
                videoId={videoId}
                opts={{ playerVars, width: '', height: '' }}
                onReady={event => setPlayer(event.target)}
                title={`Video: ${headline?.value}`}
                className="js-youtube-video relative overflow-auto max-w-2xl mx-auto bg-black rounded-md"
                iframeClassName="w-full aspect-16/9 rounded-md"
                onEnd={() => {
                  setVideoEnded(true);
                }}
              />
            </>
          )}
        </div>
        {intro && <Text className="text-lg mt-16 text-center text-white" field={intro} tag="p" />}
      </div>
    </Wrapper>
  );
};

export { createPlayerVars, ModalVideo as default };
